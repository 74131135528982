import BrandCard from './BrandCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import BrandListUtil,{IBrandListDataObj} from './brandListUtil';
import language from './brandLanguage'
export default defineComponent ({
    name: 'BrandList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IBrandListDataObj=reactive<IBrandListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: BrandCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/brand/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BrandListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            //options.pageInfo.columns.forEach((item:any)=>{
            //    if(item.prop=='F_DY_FLAG')item.formatter=utils.UtilPub.formatterYesNoStatus;
            //})
            return options;
        }
        return{
            ...toRefs(dataObj),formatPageInfo
        }
    }
});